@import "../../../styles/variables.module";
.contentWrapper {
  padding-bottom: 30px;
  padding-top: 0;
  @include large_device {
    padding-top: 30px;
  }
  .mainWrapper {
    .contentBox {
      //margin-bottom: 40px;
      width: calc(100% / 2 - 30px);
      @include large_device {
        width: 100%;
        margin-bottom: 10px;
      }
      table {
        border: 1px solid $desc-font;
        width: 100%;
        border-collapse: separate;
        tr, td {
          border: 1px solid $desc-font;
        }
        td {
          padding: 10px;
        }
      }
    }
  }
}
.mainWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  .contentBox {
    line-height: 28px;
    &:last-child, &:nth-last-child(2) {
      margin-bottom: 0;
    }
    a {
      font-weight: 500;
      color: $theme-color;
    }
    .list {
      column-count: 3;
      margin: 0 30px;
      li {
        margin-bottom: 0;
      }
    }
    p {
      @include large_device {
        font-size: 14px;
        line-height: 24px;
      }
    }
    ul,
    ol {
      padding-left: 20px;
      li {
        margin-bottom: 10px;
        list-style: disc;
        line-height: 30px;
        @include large_device {
          line-height: 24px;
        }
      }
    }
    .content {
      font-size: 20px;
      color: $theme-custom-color;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 28px;
      position: relative;
      @include large_device {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 20px;
        color: $theme-custom-color;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 28px;
        @include mobile_device {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .desc {
      width: 100%;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 30px;
      color: $theme-custom-color;
      @include large_device {
        font-size: 14px;
        line-height: 24px;
      }
      h2 {
        font-size: 16px;
        line-height: 30px;
        color: $theme-custom-color;
        font-weight: 600;
      }
    }
  }
}
.combinedContentWrapper {
  max-height: 420px;
  overflow: auto;
  max-width: 1320px;
  margin: 150px auto 60px;
  ~ .faq {
    padding-bottom: 100px;
  }
  &.empty {
    display: none;
  }
}
#howwork {
  + .combinedContentWrapper {
    margin-top: 50px;
    margin-bottom: 150px;
  }
}